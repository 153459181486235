export class VerifyTransactionDTO {
  //scope = null;//string
  //store_hash = null; //string
  CartId = null; //string
  CardInfo = null; //TransactionDTO_CardInfo

  constructor() {}
}

export class CheckBalanceTransactionDTO {
  Id = null; //string
  CartId = null; //string
  CardInfo = null; //TransactionDTO_CardInfo
  CustomerInfo = null; //TransactionDTO_CustomerInfo

  constructor() {}
}

export class ApplyTransactionDTO {
  CartId = null; //string
  CardInfo = null; //TransactionDTO_CardInfo
  CustomerInfo = null; //TransactionDTO_CustomerInfo

  constructor() {}
}

export class CommitTransactionDTO {
  CartId = null; //string
  OrderId = null; //string
  AllCardInfo = null; //List<TransactionDTO_CardInfo>
  CustomerInfo = null; //TransactionDTO_CustomerInfo

  constructor() {}
}


export class AuthorizeAndCaptureTransactionDTO {
    CartId = null; //string
    CardInfo = null; //AuthorizeAndCapture_CardInfo

    constructor() { }
}

export class AuthorizeAndCapture_CardInfo {
    Token = ""; //string

    constructor() { }
}

export class TransactionDTO_CardInfo {
  Amount = null; //decimal?
  Number = null; //string
  ExpYr = null; //string
  ExpMth = null; //string
  SecurityCode = ""; //string

  constructor() {}
}

export class TransactionDTO_CustomerInfo {
  FirstName = null; //string
  LastName = null; //string
  AddressLine1 = null; //string
  PostalCode = null; //string
  Email = null; //string

  constructor() {}
}

export default null;
