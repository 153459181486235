import { Menu, Transition } from "@headlessui/react";
import { Input, SelectMenu } from "@mojoactive/components";
import { Fragment } from "react";
import { IconFilter, IconX } from "@tabler/icons-react";

export default function ToolbarFilters({ filters, columns, onSelect }) {
  const fields = columns
    .filter((o) => (typeof o.filter === "undefined" ? true : o.filter))
    .map((o) => ({ value: o.field, text: o.label }))
    .filter((o) => o.text);
  const ops = [{ value: "equals", text: "equals" }];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          <span className="flex items-center rounded-md border bg-gray-100 px-2 py-1 text-[14px] font-medium hover:bg-gray-200">
            <IconFilter size={18} />
            <span className="ml-1">Filters</span>
            {filters?.field && filters?.op && filters?.value && <span className="ml-2 h-2 w-2 rounded-full bg-orange-500"></span>}
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-1 w-[600px] origin-top-right rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex items-center space-x-3 p-4">
            <div className="flex-1">
              <SelectMenu
                options={fields}
                value={filters?.field || fields?.[0]?.value}
                onChange={(item) => onSelect((prev) => ({ ...prev, field: item.value }))}
              />
            </div>
            <div className="flex-1">
              <SelectMenu
                options={ops}
                value={filters?.op || ops?.[0]?.value}
                onChange={(item) => onSelect((prev) => ({ ...prev, op: item.value }))}
              />
            </div>
            <div className="flex-1">
              <Input placeholder="value" value={filters?.value} onChange={(value) => onSelect((prev) => ({ ...prev, value }))} />
            </div>
            {filters?.field && filters?.op && filters?.value && (
              <button className="rounded-md border bg-gray-100 p-[8px] hover:bg-gray-200" onClick={() => onSelect({ op: "equals" })}>
                <IconX className="h-5 w-5" />
              </button>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
