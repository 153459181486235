import { MerchantCheckoutProvider } from "core/admin/MerchantCheckout/useMerchantCheckout";
import { useAuth } from "core/hooks/useAuth";
import dynamic from "next/dynamic";
import Head from "next/head";

export default function CorePageRenderer(props) {
  const { user } = useAuth();

  const PublicRoutes = ["/auth/signin", "/auth/signup", "/auth/forbidden", "/auth/forgot-password", "/auth/change-password"];

  const CorePages = {
    "/admin": dynamic(() => import("core/pages/admin")),
    "/auth/signin": dynamic(() => import("core/pages/auth/signin")),
    "/auth/signup": dynamic(() => import("core/pages/auth/signup")),
    "/auth/forbidden": dynamic(() => import("core/pages/auth/forbidden")),
    "/auth/forgot-password": dynamic(() => import("core/pages/auth/forgot-password")),
    "/auth/change-password": dynamic(() => import("core/pages/auth/change-password")),
    "/source-codes": dynamic(() => import("core/pages/source-codes")),
    "/nav-builder": dynamic(() => import("core/pages/nav-builder")),
    "/merchant-checkout": () => {
      const MerchantCheckout = dynamic(() => import("core/admin/MerchantCheckout/MerchantCheckout"));
      return (
        <MerchantCheckoutProvider>
          <MerchantCheckout />
        </MerchantCheckoutProvider>
      );
    },
  };

  let Component = null;
  const route = props.route?.split("?")[0];

  if (CorePages[route]) {
    Component = CorePages[route];
  }

  if (route.includes("/auth")) {
    Component = CorePages[route];
  }

  if (route.includes("/admin")) {
    Component = CorePages["/admin"];
  }

  const showHeader = user && !PublicRoutes.includes(route);

  return (
    <>
      <Head>
        <style dangerouslySetInnerHTML={{ __html: showHeader ? ".main-navigation { display: block !important; }" : "" }}></style>
      </Head>
      {Component ? <Component /> : props.children || null}
    </>
  );
}
