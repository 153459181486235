import { Menu, Transition } from "@headlessui/react";
import { Prompt, SelectMenu, Tooltip } from "@mojoactive/components";
import { Exporters } from "core/utils/utilities";
import { Fragment, useState } from "react";
import { IconDatabaseExport, IconFileExport, IconTableExport } from "@tabler/icons-react";

export default function ToolbarExport({ options, raw, data, exporter, onExportAll }) {
  const [format, setFormat] = useState("csv");

  const handleExport = async (method) => {
    let exportData = method == "current" ? data : raw;

    if (method == "all" && typeof onExportAll === "function") {
      const proceed = await Prompt.Confirm({
        title: "Export All Data",
        text: "Exporting all data may take awhile if there's a lot of data. Are you sure you want to continue?",
        confirmButton: "Yes, continue",
        confirmButtonColor: "primary",
      });
      if (proceed) {
        return onExportAll(format);
      } else {
        return;
      }
    }

    const filename = options?.filename || "export";
    const filenameWithoutExtension = filename.replace(/\.[^/.]+$/, "");

    switch (format) {
      case "csv":
        Exporters.JsonToCsv(
          exportData?.map((o) => (typeof exporter === "function" ? exporter(o) : o)),
          `${filenameWithoutExtension}.csv`
        );
        break;
      case "json":
        Exporters.JsonToFile(
          exportData.map((o) => (typeof exporter === "function" ? exporter(o) : o)),
          `${filenameWithoutExtension}.json`
        );
        break;
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          <span className="flex items-center rounded-md border bg-gray-100 px-2 py-1 text-[14px] font-medium hover:bg-gray-200">
            <IconTableExport size={18} />
            <span className="ml-1">Export</span>
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-1 w-56 origin-top-right rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="border-b px-3 pb-3 pt-3">
            <SelectMenu
              label="Export Format"
              value={format}
              options={[
                { value: "csv", text: "Excel (CSV)" },
                { value: "json", text: "JSON" },
              ]}
              onChange={(item) => setFormat(item.value)}
            ></SelectMenu>
          </div>

          <Menu.Item>
            {() => (
              <Tooltip
                content={
                  <div>
                    <div>Exports with filters, search results, column sorting, and/or field selections.</div>
                  </div>
                }
                placement="left"
              >
                <button className={`group flex w-full items-center px-4 py-2 text-sm hover:bg-gray-100`} onClick={() => handleExport("current")}>
                  <IconFileExport size={18} className="mr-2" />
                  <span className="truncate">Export Current Table</span>
                </button>
              </Tooltip>
            )}
          </Menu.Item>
          <Menu.Item>
            {() => (
              <Tooltip
                className="group"
                content={
                  <div>
                    <div>Exports all available data and fields.</div>
                  </div>
                }
                placement="left"
              >
                <button className={`group flex w-full items-center px-4 py-2 text-sm hover:bg-gray-100`} onClick={() => handleExport("all")}>
                  <IconDatabaseExport size={18} className="mr-2" />
                  <span className="truncate">Export All Data</span>
                </button>
              </Tooltip>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
