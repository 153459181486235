import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import classnames from "core/utils/classnames";
import { Formatters } from "core/utils/utilities";
import { useEffect, useMemo, useState } from "react";

export default function AdminTablePagination({ pagination }) {
  const [options, setOptions] = useState(pagination);

  useEffect(() => {
    if (!pagination.per_page) return;
    setOptions(pagination);
  }, [pagination]);

  const { current_page = 1, total = 0, total_pages, onNext, onPrev, onNumber } = options || {};

  // const onFirst = () => {
  //   onNumber(1);
  // };

  // const onLast = () => {
  //   onNumber(total_pages);
  // };

  const PageNumber = ({ number, onClick }) => (
    <button
      onClick={() => onClick(number)}
      className={classnames(
        "relative z-10 inline-flex items-center border px-3 py-2 text-sm font-medium tabular-nums focus:z-20",
        number === current_page ? "z-30 border-orange-300 bg-orange-100 text-orange-600" : "border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
      )}
    >
      {number}
    </button>
  );

  const pageNumbers = useMemo(() => {
    let pagesToShow = 5;
    let start = current_page - Math.floor(pagesToShow / 2);
    let end = current_page + Math.floor(pagesToShow / 2);
    if (start < 1) {
      start = 1;
      end = pagesToShow;
    }
    if (end > total_pages) {
      start = total_pages - pagesToShow + 1;
      end = total_pages;
    }
    let range = Array.from({ length: end - start + 1 }, (_, i) => start + i);
    return range.filter((n) => n > 0);
  }, [current_page, total_pages]);

  return (
    <div className="mt-auto flex items-center justify-between rounded-b-lg border-t border-gray-200 bg-gray-50 px-2 py-2">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={onPrev}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={onNext}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          {total !== 0 && (
            <p className="text-sm text-gray-700">
              Page <span className="font-medium">{current_page}</span>{" "}
              {total_pages ? (
                <>
                  of <span className="font-medium">{total_pages}</span>
                </>
              ) : (
                ""
              )}
              <span className="mx-2 text-gray-300">|</span>
              <span className="font-medium">{Formatters.Number(total)}</span> results
            </p>
          )}
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            {/* {total_pages && (
              <button
                onClick={onFirst}
                disabled={current_page === 1}
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              >
                <span className="sr-only">Next</span>
                <IconChevronsLeft className="h-5 w-5" aria-hidden="true" />
              </button>
            )} */}
            <button
              onClick={onPrev}
              disabled={current_page === 1}
              className={classnames(
                "relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",
                "rounded-l-md"
              )}
            >
              <span className="sr-only">Previous</span>
              <IconChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>

            {(total_pages && pageNumbers.length > 0 && pageNumbers?.map((o, i) => <PageNumber key={i} number={o} onClick={onNumber} />)) || null}

            <button
              onClick={onNext}
              disabled={current_page === total_pages}
              className={classnames(
                "relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20",
                "rounded-r-md"
              )}
            >
              <span className="sr-only">Next</span>
              <IconChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* {total_pages && (
              <button
                onClick={onLast}
                disabled={current_page === total_pages}
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              >
                <span className="sr-only">Next</span>
                <IconChevronsRight className="h-5 w-5" aria-hidden="true" />
              </button>
            )} */}
          </nav>
        </div>
      </div>
    </div>
  );
}
