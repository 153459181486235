import { Transition } from "@headlessui/react";
import classnames from "core/utils/classnames";
import { Loading as LoadingIcon } from "@mojoactive/components";

export default function Loading({ className = "", show, cover, message }) {
  return (
    <Transition
      show={show ?? true}
      className={classnames(cover && "absolute top-0 left-0 z-20 flex h-full w-full items-center justify-center bg-white/30 backdrop-blur")}
      enter="transition-opacity duration-200"
      enterFrom="opacity-0 backdrop-blur-none"
      enterTo="opacity-100 backdrop-blur-sm"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100 backdrop-blur-sm"
      leaveTo="opacity-0 backdrop-blur-none"
    >
      <div className={`flex flex-col items-center justify-center py-5 ${className}`}>
        <div className="flex items-center justify-center space-x-3 py-10 text-sm font-medium text-gray-900">
          <LoadingIcon />
          <span className="">{message ? message : message !== false && ""}</span>
        </div>
      </div>
    </Transition>
  );
}
