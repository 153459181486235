export class RemoveRequestDTO {
  CartId = null; //String
  checkout = null; //String
  cards = null; //List<CheckoutCardDTO>

  constructor() {}
}

export class RemoveResponseDTO {
  iHttpStatusCode = null; //int
  lstCodes = null; //List<string>
  sMessage = null; //String

  constructor() {}
}
