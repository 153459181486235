// eslint-disable-next-line no-unused-vars
import axios, { AxiosRequestConfig } from "axios";
// used for client side API requests to /api
/**
 * An API Wrapper that can be used independently or with useSWR
 * @param {string} url the api endpoint url
 * @param {AxiosRequestConfig} options the axios config options
 * @returns
 */
export default async function fetcher(url, options = {}) {
  axios.defaults.withCredentials = true;

  // auto append headers within the same ecosystem
  let headers = { ...(options.headers || {}) };

  if (window?.mja?.selectedStore) {
    headers["X-Store-Hash"] = window?.mja?.selectedStore?.storeHash;
    headers["X-Account-Id"] = window?.mja?.selectedStore?.accountId;
  }

  const { data } = await axios({ url, withCredentials: true, headers, ...options });
  return data;
}
