import { useStore } from "core/hooks/useStore";
import { useRouter } from "next/router";
import { createContext, useContext, useMemo, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import { useFeature } from "../Feature";
import FileManagerModal from "./FileManagerModal";

const FileManagerContext = createContext();

/**
 * @typedef {Object} FileManagerHook
 * @property {Array} files
 * @property {boolean} initialLoading
 * @property {boolean} loading
 * @property {Function} refresh
 * @property {boolean} showModal
 * @property {Object} filters
 * @property {Function} setShowModal
 * @property {Function} setFilters
 */

/**
 * Hook for interacting with the file manager
 * @returns {FileManagerHook}
 */
export const useFileManager = () => useContext(FileManagerContext);

export const FileManagerProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({});
  const { mutate } = useSWRConfig();
  const { store } = useStore();
  const features = useFeature();
  const router = useRouter();
  const enabled = store && features?.enabled["fileManager"] === true && (showModal || router.asPath === "/admin/file-manager");
  const { data: files, error, isValidating } = useSWR(enabled ? "/api/store/file-manager" : null, { revalidateOnFocus: false });
  const { data: stats } = useSWR(enabled ? "/api/store/file-manager?stats=1" : null, { revalidateOnFocus: false });

  const categories = useMemo(() => {
    if (!files) return [];
    const cats = files.map((o) => o.metadata?.category);
    return [...new Set(cats)].sort((a, b) => a.localeCompare(b));
  }, [files]);

  const hookData = {
    files,
    stats,
    initialLoading: !files && !error,
    loading: isValidating,
    refresh: () => {
      mutate("/api/store/file-manager");
      mutate("/api/store/file-manager?stats=1");
    },
    showModal,
    filters,
    categories,
    setShowModal,
    setFilters,
  };

  return (
    <FileManagerContext.Provider value={hookData}>
      {children}
      <FileManagerModal />
    </FileManagerContext.Provider>
  );
};
