import { useStore } from "core/hooks/useStore";
import { DataProvider } from "lib/data-provider";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";
import { MerchantCheckoutUtils } from "./MerchantCheckoutUtil";
import { isValidAddress } from "./MerchantCheckoutCommon";
import useTabFocus from "core/hooks/useTabFocus";
import { Loading } from "@mojoactive/components";

const MerchantCheckoutContext = createContext();

/**
 * @typedef {Object} MerchantCheckoutContext
 * @property {import('./MerchantCheckoutUtil').MerchantCheckoutUtilsType} util
 */

/**
 * @returns {MerchantCheckoutContext}
 */
export const useMerchantCheckout = () => useContext(MerchantCheckoutContext);

export function MerchantCheckoutProvider(props) {
  const [customer, setCustomer] = useState();
  const [shipping, setShipping] = useState();
  const [billing, setBilling] = useState();
  const [products, setProducts] = useState([]);
  const [payment, setPayment] = useState();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState({});
  const [config, setConfig] = useState();
  const [cart, setCart] = useState();
  const { store, api } = useStore();
  const [checkout, setCheckout] = useLocalStorage("merchant-checkout", {});
  const [orderId, setOrderId] = useLocalStorage("merchant-checkout-order-id", null);
  const isTabFocused = useTabFocus();

  const isValid = useMemo(() => {
    const hascustomer = checkout?.cart?.customer_id !== 0;
    const hasBillingAddress = isValidAddress(checkout?.billing_address);
    const hasConsignments = checkout?.consignments?.length;
    const hasShippingAddress = isValidAddress(checkout?.consignments?.[0]?.shipping_address);
    const hasShippingOption = checkout?.consignments?.[0]?.selected_shipping_option?.id !== undefined;
    const hasPhysicalItems = checkout?.cart?.line_items?.physical_items?.length > 0;


    //console.log(hascustomer, "hascustomer", hasBillingAddress, "hasBillingAddress", hasConsignments, "hasConsignments", hasShippingAddress, "hasShippingAddress", hasShippingOption, "hasShippingOption", hasPhysicalItems, "hasPhysicalItems") 

    let required = [hasBillingAddress];

    if(config?.customer?.required){
      required = [...required, hascustomer];
    }

    if (hasConsignments || hasPhysicalItems) {
      required = [...required, hasShippingAddress, hasShippingOption];
    }

    const valid = required.every(Boolean);
    return valid;

  }, [checkout]);

  const sharedData = {
    store,
    cart,
    checkout,
    customer,
    shipping,
    billing,
    products,
    payment,
    config,
    loading,
    isValid,
    order,
    orderId,
    setCart,
    setCheckout,
    setCustomer,
    setShipping,
    setBilling,
    setProducts,
    setPayment,
    setConfig,
    setLoading,
    setOrder,
    setOrderId,
  };

  const util = useCallback(MerchantCheckoutUtils(store, sharedData), [store, sharedData, checkout]);

  const hookData = { util, ...sharedData };

  const handleInit = async () => {
    if (checkout.id) {
      try {
        setLoading((prev) => ({ ...prev, cart: true, customer: true }));
        const cart = await hookData.util.cart.load();

        const data = await Promise.all(
          [
            cart?.id ? hookData.util.checkout.load() : null,
            cart?.customer_id ? DataProvider.CustomersV3.CustomersGet(store, `?id:in=${cart.customer_id}&include=addresses`) : null,
          ].filter(Boolean)
        );

        if (data?.[0]) {
          setCheckout({ ...checkout, ...data?.[0] });
        }

        if (data?.[1]?.length) {
          setCustomer(data?.[1]?.[0]);
        }

        setCart(cart);
      } catch (ex) {
        const statusCode = ex?.response?.data?.statusCode || ex?.response?.status?.code;
        if (statusCode === 404) {
          setCart(null);
          setCheckout({ ...checkout, id: null });
        }
        console.log(ex);
      }
      setLoading((prev) => ({ ...prev, cart: false, checkout: false, customer: false }));
    }
  };

  useEffect(() => {
    if (store) {
      setLoading((prev) => ({ ...prev, config: true }));
      api
        .get("/api/checkout/config")
        .then((data) => setConfig(data))
        .catch(() => {})
        .finally(() => setLoading((prev) => ({ ...prev, config: false })));
    }
  }, [store]);

  useEffect(() => {
    if (orderId && store) {
      DataProvider.OrdersV2.GetAnOrder(store, orderId).then((data) => {
        DataProvider.Custom.PopulateOrderRelations(store, data).then((data) => {
          setOrder(data);
        });
      });
    }
  }, [orderId, store]);

  useEffect(() => {
    if (checkout.id && store?.storeHash) {
      handleInit();
    }
  }, [store]);

  useEffect(() => {
    if (isTabFocused) {
      // refresh the customer data on tab focus
      if (cart?.customer_id) {
        DataProvider.CustomersV3.CustomersGet(store, `?id:in=${cart.customer_id}&include=addresses`).then((data) => {
          if (data?.[0]) setCustomer(data?.[0]);
        });
      }
    }
  }, [isTabFocused]);

  return (
    <MerchantCheckoutContext.Provider value={hookData}>
      {config ? props.children : <Loading className="mx-auto my-20" />}
    </MerchantCheckoutContext.Provider>
  );
}
