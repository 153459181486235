import { Menu, Transition } from "@headlessui/react";
import classnames from "core/utils/classnames";
import { sortBy } from "lodash";
import { Fragment } from "react";
import { IconSelect } from "@tabler/icons-react";

export default function ToolbarFields({ fields, columns, onSelect }) {
  const handleSelect = (checked, field) => {
    if (checked) {
      onSelect(sortBy([...fields, field], (o) => columns.findIndex((c) => c.field == o.field)));
    } else {
      onSelect(
        sortBy(
          fields.filter((f) => f != field),
          (o) => columns.findIndex((c) => c.field == o.field)
        )
      );
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button>
          <span className="flex items-center rounded-md border bg-gray-100 px-2 py-1 text-[14px] font-medium hover:bg-gray-200">
            <IconSelect size={18} />
            <span className="ml-1">Fields</span>
            {fields?.length != columns?.length && <span className="ml-2 h-2 w-2 rounded-full bg-orange-500"></span>}
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-1 max-h-[50vh] w-80 origin-top-right overflow-auto rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="mja-components py-2">
            <label
              className={classnames(
                "text-gray-700 hover:bg-gray-100 hover:text-gray-900",
                "flex items-center px-4 py-[5px] text-sm text-[13px] font-medium leading-4"
              )}
            >
              <input
                type="checkbox"
                className="mr-2 h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                checked={fields?.length == columns?.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    onSelect(columns);
                  } else {
                    onSelect([]);
                  }
                }}
              />
              All Fields
            </label>
            {columns?.map((o, i) => (
              <label
                key={i}
                className={classnames(
                  "text-gray-700 hover:bg-gray-100 hover:text-gray-900",
                  "flex items-start px-4 py-[5px] text-[14px] font-medium leading-4"
                )}
              >
                <input
                  type="checkbox"
                  className="mr-2 h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                  checked={fields?.find((f) => f.field == o.field) ? true : false}
                  onChange={(e) => handleSelect(e.target.checked, o)}
                />
                {o.label}
              </label>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
