import { Toggle, classNames } from "@mojoactive/components";
import { IconDeviceDesktop, IconWand } from "@tabler/icons-react";
import { useLocalStorage } from "react-use";
import { MJAProtected } from "../MJA";
import { HealthReport } from "./HealthReport";

export const StoreConnection = ({ context }) => {
  const handleStopImpersonate = () => {
    window.location.href = "/api/admin/impersonate?stop=true";
  };

  const [showHealth, setShowHealth] = useLocalStorage("ip-usage-monitor", false);

  return !context ? null : (
    <>
      <div className="fixed bottom-0 left-0 z-30 print:hidden">
        <div className="group flex items-center">
          <div className="fixed -left-80 bottom-1 z-10 mr-3 w-full max-w-xs rounded-lg border border-gray-800 bg-gray-800 leading-4 text-gray-300 shadow-xl transition-all duration-300 ease-in-out group-hover:left-1">
            {context?.localhost && (
              <div className="flex rounded-tl-lg rounded-tr-lg bg-blue-600 p-4 font-bold">
                <div className="leading-5">
                  <div className="flex items-center">
                    <IconWand className="mr-3" size={20} />
                    <div style={{ fontSize: 17 }}>Development Mode</div>
                  </div>
                  <div style={{ fontSize: 13 }} className="mt-1 text-sm font-normal leading-4 text-gray-400">
                    The localhost store connection is based on the store hash set in the .env.local file.
                  </div>
                  <div className="text-gray-40 -mx-1.5 -mb-1 mt-2 rounded-lg bg-blue-800 p-3 font-mono" style={{ fontSize: 13, lineHeight: 1.4 }}>
                    <div className="opacity-60">{"// .env.development"}</div>
                    <div className="">
                      <span className="text-amber-300">LOCALHOST_STORE_HASH</span>=
                      <span className="text-sky-300">&quot;{context?.storeHash}&quot;</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={classNames("flex bg-gray-900 p-4 font-bold", !context?.localhost && "rounded-tl-lg rounded-tr-lg")}>
              <IconDeviceDesktop size={30} className="mr-4 text-gray-400" />
              <div>
                <div style={{ fontSize: 17 }}>Store Connection</div>
                <div style={{ fontSize: 13 }} className="text-sm font-normal text-gray-400">
                  You&apos;re connected to the following:
                </div>
              </div>
            </div>

            <div className="divide-y divide-gray-700 text-sm">
              <div className="grid grid-cols-3 px-4 py-3">
                <div className="text-gray-400">Storefront</div>
                <div className="col-span-2 truncate text-right">{context?.name}</div>
              </div>
              <div className="grid grid-cols-3 px-4 py-3">
                <div className="text-gray-400">Store Hash</div>
                <div className="col-span-2 text-right">{context?.storeHash}</div>
              </div>
              <div className="grid grid-cols-2 px-4 py-3">
                <div className="text-gray-400">Store Environment</div>
                <div className="text-right capitalize">{context?.environment}</div>
              </div>
              <div className="grid grid-cols-2 px-4 py-3">
                <div className="text-gray-400">Hosting Environment</div>
                <div className="text-right capitalize">{context?.hosting}</div>
              </div>
              <div className="grid grid-cols-3 px-4 py-3">
                <div className="text-gray-400">User</div>
                <div className="col-span-2 text-right">{context?.user?.email}</div>
              </div>
              <MJAProtected>
                <div className="grid grid-cols-2 px-4 py-3">
                  <div className="text-gray-400">App Usage Monitor</div>
                  <div className="flex items-center justify-end text-right">
                    <Toggle checked={showHealth} onChange={() => setShowHealth(!showHealth)} />
                  </div>
                </div>
              </MJAProtected>
            </div>
          </div>
          <span className="p-2 opacity-50 hover:opacity-100">
            <IconDeviceDesktop size={18} />
          </span>
        </div>
      </div>

      <MJAProtected>
        <HealthReport poll={showHealth} />
      </MJAProtected>

      {context?.user?.impersonating && (
        <div className="fixed bottom-1 left-0 right-0 z-30 mx-auto flex h-[40px] w-[300px] items-center justify-between rounded-full bg-orange-300 px-1 shadow-md">
          <span className="ml-4 mt-px truncate leading-3 text-orange-800">
            <div className="font-medium">Impersonating</div>
            <span className="truncate text-xs">{context?.user?.email}</span>
          </span>
          <button className="rounded-full bg-orange-500 px-6 py-1 font-medium text-white hover:bg-orange-600" onClick={handleStopImpersonate}>
            Stop
          </button>
        </div>
      )}
    </>
  );
};
