import axios from "axios";
import { format, formatDistanceStrict } from "date-fns";
import { parse } from "json2csv";
import jwt from "jsonwebtoken";
import { isNumber } from "lodash";
import orderBy from "lodash/orderBy";
import { customAlphabet, nanoid } from "nanoid";
import * as nanoidDictionary from "nanoid-dictionary";
import slugify from "slugify";
import { v4 as uuidv4 } from "uuid";

// ------------------------------------------------
// Formatters
// Utilities that format data
// ------------------------------------------------
export const Formatters = {
  Dollar: (str) => new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(str || 0),
  Number: (str) => new Intl.NumberFormat("en-US").format(str || 0),
  Slugify: (str, options = { lower: true, strict: true }) => slugify(str, options),
  Normalize: (str) => (str ? str.toLowerCase().trim() : str),
  Pluralize: (count, str) => (isNumber(count) ? (count === 1 ? str : `${str}s`) : str),
  PrettyBytes: (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i];
  },
  PrettyMs: (ms) => {
    // return ms, 1 sec, 1 min, etc..
    if (ms === 0) return "0 ms";
    const k = 1000;
    const sizes = ["ms", "sec", "min", "hr", "day", "week", "month", "year"];
    const i = Math.floor(Math.log(ms) / Math.log(k));
    return parseFloat((ms / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  },
  TwentyFourHourToTwelveHour: (str) => {
    if (!str) return "";
    const [hours, minutes] = str.split(":");
    const hour = parseInt(hours) % 12 || 12;
    const ampm = parseInt(hours) < 12 || parseInt(hours) === 24 ? "AM" : "PM";
    return `${hour}:${minutes} ${ampm}`;
  },
  MsToHMS: (ms) => {
    if (!ms) return "";
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    return `${hours}h ${minutes}m ${seconds}s`;
  },
  HHMMtoDate: (str) => {
    const [hours, minutes] = str.split(":") || "";
    return new Date(0, 0, 0, hours, minutes);
  },
  DateToHHMM: (date) => {
    if (!date) return "";
    return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  },
  yyyyMMddToDate: (str) => {
    if (!str) return "";
    const [year, month, day] = `${str}`.split("-") || "";
    return new Date(year, month - 1, day);
  },
  Duration: (start, end) => {
    // use Intl to format the duration
    const result = formatDistanceStrict(new Date(start), new Date(end), { includeSeconds: true });
    if (result === "0 seconds") return "Instantaneous";
    return result;
  },
  JSONSafeParse: (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  },
  MaskEmail: (email) => {
    if (!email) return "";
    const [first, last] = email.split("@");
    const firstHalf = first.slice(0, 2);
    const secondHalf = first.slice(-2);
    return `${firstHalf}***${secondHalf}@${last}`;
  },
  Sanitize: (str) => {
    str = `${str || ""}`;

    // Escape special characters
    str = str.replace(/'/g, "''");
    str = str.replace(/\\/g, "\\\\");
    str = str.replace(/"/g, '\\"');
    str = str.replace(/\0/g, "\\0");
    str = str.replace(/\n/g, "\\n");
    str = str.replace(/\r/g, "\\r");
    // eslint-disable-next-line no-control-regex
    str = str.replace(/\x1a/g, "\\Z");

    return str;
  },
};

// ------------------------------------------------
// Converters
// Utilities that convert data
// ------------------------------------------------

export const Converters = {
  /**
   * Encrypts a string using AES-256-CBC
   * @param {string} text the text to encode
   */
  EncodeSecret: (text) => {
    const crypto = typeof window === "undefined" ? require("crypto") : window.crypto;
    const ENCRYPTION_KEY = (process.env.ENCRYPTION_KEY || "").slice(0, 32); // Must be 256 bits (32 characters)
    const iv = Buffer.from(ENCRYPTION_KEY.slice(0, 16), "utf8"); // Use part of the encryption key as the IV
    const cipher = crypto.createCipheriv("aes-256-cbc", ENCRYPTION_KEY, iv);
    let encrypted = cipher.update(text, "utf8", "hex");
    encrypted += cipher.final("hex");
    return `${iv.toString("hex")}:${encrypted}`;
  },
  /**
   * Decrypts a string using AES-256-CBC
   * @param {string} text the text to decode
   */
  DecodeSecret: (text) => {
    const crypto = typeof window === "undefined" ? require("crypto") : window.crypto;
    const ENCRYPTION_KEY = (process.env.ENCRYPTION_KEY || "").slice(0, 32); // Must be 256 bits (32 characters)
    const textParts = text.split(":");
    const iv = Buffer.from(textParts.shift(), "hex");
    const encryptedText = textParts.join(":");
    const decipher = crypto.createDecipheriv("aes-256-cbc", ENCRYPTION_KEY, iv);
    let decrypted = decipher.update(encryptedText, "hex", "utf8");
    decrypted += decipher.final("utf8");
    return decrypted;
  },
  /**
   * Splits an array into groups of a specified length
   * @param {Array} array - The array to split
   * @param {number} length - The length of each group
   * @returns {Array} - An array of groups
   */
  SplitInto: (array, length) => {
    const groups = [];
    for (let i = 0; i < array.length; i += length) {
      groups.push(array.slice(i, i + length));
    }
    return groups;
  },
  /**
   * Converts a flat list of objects with parent IDs to a tree structure
   * @param {Array} array - The flat list of objects
   * @param {string} parentField - The name of the field containing the parent ID
   * @returns {Array} - The tree structure
   */
  ListToTreeByParent: (array, parentField) => {
    var map = {},
      node,
      roots = [],
      i;

    for (i = 0; i < array.length; i += 1) {
      map[array[i].id] = i;
      array[i].children = [];
    }

    for (i = 0; i < array.length; i += 1) {
      node = array[i];
      if (node[parentField]) {
        array[map[node[parentField]]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  },
  /**
   * Converts a data URL to a Blob object
   * @param {string} dataurl - The data URL to convert
   * @returns {Blob} - The Blob object
   */
  DataURLtoBlob: (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  },
  /**
   * Converts a UTC date string to a localized date and time string
   * @param {string} date - The UTC date string
   * @returns {string} - The localized date and time string
   */
  UtcDateToLocale: (date) => {
    const d = new Date(date);
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
  },
};

// ------------------------------------------------
// Generators
// Utilities that generate data
// ------------------------------------------------
export const Generators = {
  /**
   * Returns a random hash
   * @returns {String}
   */
  Hash: () => nanoid(),
  ContentHash: async (content) => {
    const crypto = typeof window === "undefined" ? require("crypto") : window.crypto;
    const hash = crypto.createHash("sha256");
    hash.update(content);
    const hashHex = hash.digest("hex");
    return hashHex;
  },
  /**
   * Returns a random alpha-numeric string
   * @param {String} length the length of the string
   * @param {'alphanumeric'|'alphanumericUpper'|'lowercase'|'uppercase'|'number'|'nolookalikes'|'nolookalikesSafe'} type the type of string to generate
   */
  AlphaCode: (length = 16, type = "alphanumeric") => {
    if (type === "alphanumericUpper") {
      return customAlphabet(nanoidDictionary.numbers + nanoidDictionary.uppercase, length)();
    }
    return customAlphabet(nanoidDictionary[type], length)();
  },
  /**
   * Returns a random customer password for BigCommerce
   * @returns {String}
   */
  BigCommerceCustomerPassword: () =>
    new Array(10)
      .fill()
      .map(() => String.fromCharCode(Math.random() * 86 + 40))
      .join("") + "1b",
  /**
   * Returns an array of hours based on a step interval
   * @param {number} step the step in minutes
   * @returns {Array}
   */
  HoursInDay: (step = 30) => {
    const dt = new Date(1970, 0, 1);
    const dates = [];
    while (dt.getDate() === 1) {
      dates.push({ value: format(dt, "HH:mm"), text: format(dt, "hh:mm a") });
      dt.setMinutes(dt.getMinutes() + step);
    }
    return dates;
  },
  /**
   * Returns a hex color based on a strings content
   * @param {string} str the content to generate a hex from
   * @param {string?} bgColor the background color to check the contrast ratio against
   * @returns
   */
  HexFromString: (str, bgColor = "#ffffff") => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    const contrastRatio = getContrastRatio(color, bgColor);
    if (contrastRatio < 2) {
      color = Generators.HexFromString(str + "1", bgColor);
    }
    return color;
  },
  HexToRGB: (hex) => colorToRGB(hex),
};

export const CompareAddress = {
  /**
   * Computes a hash of the address
   * @param {any} address
   * @returns {string}
   */
  Hash: (address = {}) => {
    const address1 = address.address1 || address.street_1 || "";
    const address2 = address.address2 || address.street_2 || "";
    const city = address.city || "";
    const state = address.state || "";
    const zip = address.zip || address.postalCode || "";
    return `${address1}${address2}${city}${state}${zip}`.toLowerCase().replace(/\s/g, "");
  },
  /**
   * Determines if two addresses are the same
   * @param {any} address1
   * @param {any} address2
   * @returns {boolean}
   */
  IsSame: (address1, address2) => {
    if (!address1 || !address2) return false;
    const hash1 = CompareAddress.Hash(address1);
    const hash2 = CompareAddress.Hash(address2);
    return hash1 === hash2;
  },
};

export const Exporters = {
  JsonToCsv: (data, filename) => {
    const fields = Object.keys(data[0]);
    const opts = { fields };
    try {
      const csv = parse(data, opts);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", filename || "export.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error(err);
    }
  },
  JsonToFile: (data, filename) => {
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename || "export.json");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
};

export const DataExplorerHelper = {
  Fetch: async ({ store, data, pagination, query, args, filters, csrf }) => {
    pagination = pagination || { page: 1, limit: 50 };
    query = typeof query == "object" ? query : {};

    if (data?.pagination) {
      query.page = pagination.page;
      query.limit = query.limit || pagination.limit;
    }

    if (filters?.field && filters?.value) {
      query[filters.field] = filters.value;
    }

    let resp = await axios.post(
      "/api/admin/explore",
      { store, url: data?.url, method: data?.method, query, args },
      { headers: { "X-CSRF-TOKEN": csrf } }
    );

    return resp.data;
  },
  FetchAll: async ({ store, data, format, csrf }) => {
    const socketId = Generators.Hash();
    await axios.post(
      `/api/admin/explore?all=true&socketId=${socketId}&format=${format}`,
      { store, url: data.url, method: data.method },
      { headers: { "X-CSRF-TOKEN": csrf } }
    );
    return socketId;
  },
};

// ------------------------------------------------
// Validators
// Utilities that validate data
// ------------------------------------------------
export const Validators = {
  isUrl: (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  },
  isObjectSame: (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  },
  isNumber: (value) => {
    try {
      let num = parseFloat(value);
      return !isNaN(num) && isFinite(num);
    } catch (e) {
      return false;
    }
  },
};

// ------------------------------------------------
// BigCommerce utilities
// ------------------------------------------------
export const BigCommerceUtils = {
  BuildCategoryTree: (categories = []) => {
    // build nested array of categories
    // children point to a parent_id
    const tree = [];
    const mapped = {};

    categories.forEach((category) => {
      mapped[category.id] = category;
      category.children = [];
    });

    categories.forEach((category) => {
      if (category.parent_id !== 0) {
        mapped[category.parent_id].children.push(category);
        mapped[category.parent_id].children = orderBy(mapped[category.parent_id].children, "sort_order", "asc");
      } else {
        tree.push(category);
      }
    });

    return orderBy(tree, (props) => props.sort_order, "asc");
  },
};

// ------------------------------------------------
// PubSub Utility
// ------------------------------------------------
export const PubSub = {
  events: {},
  /**
   * Subscribe to an event
   * @param {string} event the event to subscribe to
   * @param {function} callback  the callback to execute when the event is published
   */
  subscribe: (event, callback) => {
    if (!PubSub.events.hasOwnProperty(event)) {
      PubSub.events[event] = [];
    }
    if (PubSub.events[event].indexOf(callback) === -1) {
      PubSub.events[event].push(callback);
    }
  },
  /**
   * Publish an event to all subscribers
   * @param {string} event  the event to publish to
   * @param {data?} data optional data to pass to the callback
   * @returns
   */
  publish: (event, data = {}) => {
    if (!PubSub.events.hasOwnProperty(event)) return [];
    return PubSub.events[event].map((callback) => callback(data));
  },
  /**
   * Remove a callback from an event
   * @param {string} event  the event to remove
   * @param {function} callback  the callback to remove
   * @returns
   */
  remove: (event, callback) => {
    if (!PubSub.events.hasOwnProperty(event)) return false;
    PubSub.events[event] = PubSub.events[event].filter((cb) => cb !== callback);
  },
};

export const CSV = {
  ToString: ({ data, headers }) => {
    let csv = "";
    if (headers) {
      csv += headers.map((o) => o.label).join(",") + "\n";
      data.forEach((row) => {
        headers.forEach((o) => {
          // need to escape commas
          let value = `${row[o.field] || ""}`.replace(/,/g, "");
          csv += value + ",";
        });
        csv += "\n";
      });
    } else {
      data.forEach((row) => {
        csv += Object.values(row).join(",") + "\n";
      });
    }

    return csv;
  },
  Download: ({ content, filename }) => {
    let a = document.createElement("a");
    let file = new Blob([content], { type: "text/csv" });
    a.href = URL.createObjectURL(file);
    a.download = filename || "export.csv";
    a.click();
  },
};

// ------------------------------------------------
// Custom Utilities
// Utilities that totally custom
// ------------------------------------------------
/**
 * Returns a value by matching one of the keys in an object
 * @param {Object} obj the object to match against
 * @param {String} str the string to match
 * @returns {String}
 */
export const stringMatch = (obj, str) => {
  if (!obj) return null;
  if (obj[str]) return obj[str];
  let match = "";
  for (let key of Object.keys(obj)) {
    if (key?.toLowerCase()?.includes(str.toLowerCase())) {
      match = obj[key];
      continue;
    }
  }
  return match;
};

/**
 * A utility function that waits for a specified amount of time before resolving.
 * @function sleep
 * @param {number} ms - The number of milliseconds to wait.
 * @returns {Promise<void>} - A promise that resolves after the specified amount of time has elapsed.
 * @example
 * await sleep(1000); // waits for 1 second before continuing
 */
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * A utility function that wraps a function call in a try-catch block and returns an array with the error (if any) and the data (if any).
 * @function tryCatch
 * @param {Function} fn - The function to be called.
 * @param  {...any} args - The arguments to be passed to the function.
 * @returns {Promise<Array>} - A promise that resolves to an array with the error (if any) and the data (if any).
 * @example
 * const [err, data] = await tryCatch(myFunction, arg1, arg2);
 * if (err) {
 *   console.error(err);
 * } else {
 *   console.log(data);
 * }
 */
export const tryCatch = async (fn, ...args) => {
  try {
    const data = await fn(...args);
    return [null, data];
  } catch (err) {
    return [err];
  }
};

/**
 * A utility function that wraps a function call in a try-catch block and returns an array with the error (if any) and the data (if any).
 * @function asyncTryCatch
 * @param {Function} fn - The function to be called.
 * @returns {Function} - A function that returns a promise that resolves to an array with the error (if any) and the data (if any).
 * @example
 * const [err, data] = await asyncTryCatch(myFunction)(arg1, arg2);
 */
export const asyncTryCatch =
  (fn) =>
  async (...args) => {
    try {
      const data = await fn(...args);
      return [null, data];
    } catch (err) {
      console.log(err);
      return [err];
    }
  };

/**
 * Retry a function a number of times
 * @param {function} fn the function to retry
 * @param {{ max: number, timeout: number, onRetry?: function }} options the options to pass to the retry function
 * @returns {Promise}
 */
export async function Retry(fn, options) {
  const { max, timeout, onRetry } = options || { max: 2, timeout: 20000 };

  return new Promise(async (resolve, reject) => {
    let retryCount = 0;
    while (retryCount < max) {
      try {
        const data = await fn();
        resolve(data);
        break; // exit the while loop
      } catch (ex) {
        retryCount++;
        if (typeof onRetry === "function") onRetry?.(ex);
        await new Promise((resolve) => setTimeout(resolve, timeout));
        if (retryCount === max) {
          reject(ex);
        }
      }
    }
  });
}

/**
 * Creates an encoded query string from an object of key-value pairs.
 * @param {Object} params - An object containing key-value pairs to be converted to a query string.
 * @returns {string} - An encoded query string.
 */
export const createEncodedQueryString = (params) => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    urlParams.append(key, params[key]);
  });
  return encodeURIComponent(urlParams.toString());
};

/**
 * Creates a query string from an object of key-value pairs.
 * @param {Object} params - An object containing key-value pairs to be converted to a query string.
 * @returns {string} - A query string.
 */
export const createQueryString = (params) => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    urlParams.append(key, params[key]);
  });
  return `?${urlParams.toString()}`;
};

/**
 * Converts an object to a query string.
 * @param {Object} o - An object containing key-value pairs to be converted to a query string.
 * @returns {string} - A query string.
 */
export const convertObjectToQueryParams = (o = {}) => {
  var esc = encodeURIComponent;
  return Object.keys(o)
    .filter((k) => o[k] !== undefined && o[k] !== null)
    .map((k) => (Array.isArray(o[k]) ? o[k].map((cc) => esc(k) + "=" + esc(cc)).join("&") : esc(k) + "=" + esc(o[k])))
    .join("&");
};

/**
 * Converts an object to a query string for BC
 * @param {Object} o - An object containing key-value pairs to be converted to a query string.
 * @returns {string} - A query string.
 */
export const convertObjectToQueryParamsBC = (o = {}) => {
  const esc = encodeURIComponent;
  return Object.keys(o)
    .filter((k) => o[k] !== undefined && o[k] !== null)
    .map((k) => {
      // Check if the value is an array and handle accordingly
      if (Array.isArray(o[k])) {
        // Join the array values with a comma
        const values = o[k].map(esc).join(",");
        return `${esc(k)}=${values}`;
      } else {
        // Handle single values
        return `${esc(k)}=${esc(o[k])}`;
      }
    })
    .join("&");
};

export function getCustomerToken(ctx, customerId, redirectTo) {
  // https://developer.bigcommerce.com/api-docs/storefront/customer-login-api
  const dateCreated = Math.round(new Date().getTime() / 1000);
  const payload = {
    iss: process.env.BC_APP_CLIENT_ID,
    iat: dateCreated,
    jti: uuidv4(),
    operation: "customer_login",
    store_hash: ctx.storeHash,
    customer_id: customerId,
    redirect_to: redirectTo,
  };
  let token = jwt.sign(payload, process.env.BC_APP_CLIENT_SECRET, { algorithm: "HS256" });
  return token;
}

export function getImpersonatedUrl(ctx, customerId, redirectTo) {
  let token = getCustomerToken(ctx, customerId, redirectTo);
  return `${ctx.url}/login/token/${token}`;
}

/**
 * Checks if the current page is inside an iframe
 * @function isInIframe
 * @returns {boolean} - true if the current page is inside an iframe, false otherwise
 */
export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Checks if a given date is between two given times
 * @function isBetweenHour
 * @param {Date} date - The date to check
 * @param {string} start - The start time in the format "HH:mm"
 * @param {string} end - The end time in the format "HH:mm"
 * @returns {boolean} - true if the date is between the start and end times, false otherwise
 */
export const isBetweenHour = (date, start, end) => {
  const [startHour, startMinute] = start.split(":").map((o) => parseInt(o));
  const [endHour, endMinute] = end.split(":").map((o) => parseInt(o));
  const [nowHour, nowMinute] = [date.getHours(), date.getMinutes()];

  if (startHour < endHour) {
    return nowHour >= startHour && nowHour <= endHour;
  } else if (startHour > endHour) {
    return nowHour >= startHour || nowHour <= endHour;
  } else {
    return nowMinute >= startMinute && nowMinute <= endMinute;
  }
};

/**
 * Calculates the contrast ratio between two colors
 * @function getContrastRatio
 * @param {string} color1 - The first color in hex format
 * @param {string} color2 - The second color in hex format
 * @returns {number} - The contrast ratio between the two colors
 */
function getContrastRatio(color1, color2) {
  const [r1, g1, b1] = colorToRGB(color1);
  const [r2, g2, b2] = colorToRGB(color2);
  const l1 = luminance(r1, g1, b1);
  const l2 = luminance(r2, g2, b2);
  return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}

/**
 * Converts a hex color to RGB
 * @function colorToRGB
 * @param {string} color - The color in hex format
 * @returns {Array} - An array containing the RGB values of the color
 */
function colorToRGB(color) {
  const r = parseInt(color.substr(1, 2), 16);
  const g = parseInt(color.substr(3, 2), 16);
  const b = parseInt(color.substr(5, 2), 16);
  return [r, g, b];
}

/**
 * Calculates the luminance of a color
 * @function luminance
 * @param {number} r - The red component of the color (0-255)
 * @param {number} g - The green component of the color (0-255)
 * @param {number} b - The blue component of the color (0-255)
 * @returns {number} - The luminance of the color
 */
function luminance(r, g, b) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
