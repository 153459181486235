import classnames from "core/utils/classnames";

export const OrderStatus = ({ order, id, status, badge }) => {
  let statusId = `${order?.status_id || id || 0}`;
  let statusValue = order?.status || status;
  return (
    <div className={classnames("flex items-center", badge && "rounded-full border bg-white px-4 py-2")}>
      <div className={`order-${statusId} mr-2 h-3 w-3 rounded-full`}></div>
      <span>{statusValue}</span>
    </div>
  );
};
