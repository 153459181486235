import "@tremor/react/dist/esm/tremor.css";
import { useRouter } from "next/router";
import CorePageRenderer from "../pages/index";
import Head from "next/head";
import { FileManagerProvider } from "core/components/FileManager";

export default function CoreProvider({ children }) {
  const router = useRouter();
  const route = router.asPath;

  return (
    <>
      <Head>
        <link href="https://rsms.me/inter/inter.css" rel="stylesheet" />
      </Head>
      <FileManagerProvider>
        <CorePageRenderer route={route}>{children}</CorePageRenderer>
      </FileManagerProvider>
    </>
  );
}
