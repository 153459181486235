import { Modal } from "@mojoactive/components";
import { useFileManager } from ".";
import FileManager from "./FileManager";

export default function FileManagerModal() {
  const { showModal, setShowModal } = useFileManager();

  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      title="File Manager"
      size="6xl"
      closeButton
      scrollable={false}
      confirmButton={false}
      cancelButton={false}
    >
      {showModal && <FileManager mode="modal" />}
    </Modal>
  );
}
