import { Loading, Popover, ProgressBar, classNames } from "@mojoactive/components";
import { IconDotsVertical, IconMaximize, IconMinimize, IconSortAscending, IconSortDescending } from "@tabler/icons-react";
import dotProp from "dot-prop";
import { AnimatePresence, motion } from "framer-motion";
import { cloneDeep } from "lodash";
import { createContext, forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDebounce, useLockBodyScroll } from "react-use";
import { FixedSizeList } from "react-window";
import { Validators } from "../../utils/utilities";
import { OrderStatus } from "../common";
import AdminTablePagination from "./AdminTablePagination";
import ToolbarExport from "./Toolbar/ToolbarExport";
import ToolbarFields from "./Toolbar/ToolbarFields";
import ToolbarFilters from "./Toolbar/ToolbarFilters";

const VirtualTableContext = createContext({});

/**
 * A table component for displaying and manipulating data in an admin interface.
 *
 * @param {Object} props - The props object.
 * @param {Array} props.columns - An array of objects representing the columns of the table.
 * @param {Array} props.data - An array of objects representing the data to be displayed in the table.
 * @param {boolean} props.toolbar - Whether or not to display the toolbar.
 * @param {function} props.transform - A function to transform the data before it is displayed.
 * @param {Object} props.filter - An object representing the current filter.
 * @param {Array} props.filters - An array of objects representing the available filters.
 * @param {Object} props.pagination - An object representing the current pagination state.
 * @param {function} props.onPaginate - A function to handle pagination events.
 * @param {function} props.onFilter - A function to handle filter events.
 * @param {function} props.onSort - A function to handle sort events.
 * @param {function} props.onSearch - A function to handle search events.
 * @param {function} props.onClick - A function to handle click events.
 * @param {boolean} props.loading - Whether or not the table is currently loading data.
 * @param {boolean} props.progress - Whether or not to display a progress bar.
 * @param {number} props.height - The height of the table.
 * @param {boolean} props.divide - Whether or not to display dividing lines between rows.
 * @param {number} props.minHeight - The minimum height of the table.
 * @param {number} props.maxHeight - The maximum height of the table.
 * @param {boolean} props.compact - Whether or not to display the table in compact mode.
 * @param {string} props.emptyMessage - The message to display when the table is empty.
 * @param {function} props.onExportAll - A function to handle exporting all data.
 * @param {Array} props.fields - An array of objects representing the fields to display.
 * @param {Object} props.footer - An object representing the footer of the table.
 * @param {boolean} props.virtualized - Whether or not to use virtualization.
 * @param {boolean} props.printable - Whether or not the table is printable.
 * @returns {JSX.Element} - The rendered table component.
 */
export default function AdminTable(props) {
  const [fullscreen, setFullscreen] = useState(false);
  const [sort, setSort] = useState({ dir: null, field: null });
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [fields, setFields] = useState();
  const [filters, setFilters] = useState({ op: "equals" });

  useDebounce(() => setSearch(searchQuery), 500, [searchQuery]);

  useLockBodyScroll(fullscreen);

  const {
    columns,
    data,
    toolbar,
    flush,
    transform,
    exporter,
    filter,
    filters: availableFilters,
    pagination,
    onPaginate,
    onFilter,
    onSort,
    onSearch,
    loading,
    progress,
    height,
    divide,
    minHeight,
    maxHeight,
    compact,
    emptyMessage,
    onExportAll,
    fields: initialFields,
    footer,
    virtualized,
    printable,
  } = props;

  useEffect(() => {
    if (availableFilters && !filters) setFilters(availableFilters);
  }, [availableFilters]);

  useEffect(() => {
    if (initialFields) setFields(initialFields?.map((o) => ({ field: o })));
  }, [initialFields]);

  useEffect(() => {
    if (!initialFields) setFields(columns);
  }, [columns]);

  useEffect(() => {
    if (filter?.value) {
      setFilters(filter);
    }
  }, [filter]);

  const tableData = useMemo(() => {
    if (!data) return [];

    let temp = cloneDeep(data);

    // sort
    if (sort.dir && sort.field) {
      if (typeof onSort === "function") {
        temp = onSort(temp, sort);
      } else {
        temp = temp?.sort((a, b) => {
          let customSortField = columns.find((o) => o.field == sort.field)?.sort;
          if (customSortField) {
            a = dotProp.get(a, customSortField);
            b = dotProp.get(b, customSortField);
          } else {
            a = a[sort.field];
            b = b[sort.field];
          }

          if (Validators.isNumber(a) && Validators.isNumber(b)) {
            if (sort.dir == "asc") return a - b;
            return b - a;
          } else {
            if (sort.dir == "asc") return a?.toString()?.localeCompare(b?.toString());
            return b?.toString()?.localeCompare(a?.toString());
          }
        });
      }
    }

    if (toolbar) {
      // search
      if (search) {
        if (typeof onSearch === "function") {
          temp = onSearch(temp, search);
        } else {
          temp = temp.filter((row) => {
            return Object.values(row).some((value) => {
              return value?.toString()?.toLowerCase()?.includes(search?.toLowerCase());
            });
          });
        }
      }

      // filters
      if (!onFilter && filters?.op && filters?.field && filters?.value) {
        temp = temp.filter((row) => {
          let value = row[filters?.field] || dotProp.get(row, filters?.field);
          if (filters.op == "equals") {
            return `${value}`.toLowerCase() == `${filters?.value}`.toLowerCase();
          }
          return true;
        });
      }

      // selected fields to show
      if (fields) {
        // temp = temp?.map((row) => {
        //   return pick(
        //     row,
        //     fields.map((o) => o.field)
        //   );
        // });
      }
    }

    return temp;
  }, [data, sort, search, fields, filters]);

  useDebounce(
    () => {
      handleFilterChange();
    },
    1000,
    [filters]
  );

  const handleFilterChange = useCallback(() => {
    onFilter?.(filters);
  }, [filters]);

  const computedHeight = useMemo(() => {
    if (!fullscreen) return height || 400;

    // compute calculated height (calc(100vh - 200px))
    let computedHeight = document.documentElement.clientHeight - 200;
    return computedHeight || 400;
  }, [height, fullscreen]);

  const footerData = typeof footer === "function" ? footer(tableData) : footer;

  return (
    <>
      <div
        className={classNames(
          "print:hidden",
          fullscreen
            ? "fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-50 p-5 backdrop-blur-sm"
            : "relative print:shadow-none",
          flush ? "" : "rounded-lg"
        )}
      >
        <div className={classNames("z-10 w-full bg-white print:shadow-none", flush ? "" : "rounded-lg border")}>
          {toolbar && (
            <div className="flex h-12 w-full items-center justify-between rounded-t-lg border-b bg-white pr-2">
              <div className="flex h-full flex-1 items-stretch">
                {toolbar.search && (
                  <input
                    type="text"
                    placeholder={toolbar.search?.placeholder || "Search..."}
                    className="mr-2 h-full w-full rounded-lg border-none px-3 text-sm focus:ring-0"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                )}
              </div>
              {(toolbar.export || toolbar.expand || toolbar.filters) && (
                <div className="flex h-full items-center space-x-2 border-l pl-2">
                  {toolbar.filters && <ToolbarFilters all={availableFilters} filters={filters} columns={columns} onSelect={setFilters} />}
                  {toolbar.fields && <ToolbarFields fields={fields} columns={columns} onSelect={setFields} />}
                  {toolbar.export && (
                    <ToolbarExport options={toolbar.export} data={tableData} raw={data} exporter={exporter} onExportAll={onExportAll} />
                  )}
                  {toolbar.expand && (
                    <button
                      className="flex items-center rounded-md border bg-gray-100 px-2 py-1 text-[14px] font-medium hover:bg-gray-200"
                      onClick={() => setFullscreen(!fullscreen)}
                    >
                      {fullscreen ? <IconMinimize size={18} /> : <IconMaximize size={18} />}{" "}
                      <span className="ml-1">{fullscreen ? "Close" : "Expand"}</span>
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
          <div className={classNames("relative w-full px-4 sm:px-6 lg:px-8", flush ? "" : "rounded-lg")}>
            <div className={classNames("flex flex-col")}>
              <div className="-mx-4 sm:-mx-6 lg:-mx-8">
                <div
                  className={classNames(
                    "inline-block min-w-full max-w-full bg-white align-middle",
                    toolbar ? "rounded-b-lg" : flush ? "" : "rounded-lg",
                    flush ? "" : "rounded-lg"
                  )}
                >
                  <div
                    className={classNames("fancy-scroll relative overflow-auto shadow-sm", toolbar ? "" : flush ? "" : "rounded-lg")}
                    style={
                      fullscreen
                        ? {
                            height: "calc(100vh - 200px)",
                          }
                        : {
                            minHeight: data?.length === 0 || loading ? (minHeight ? minHeight : 120) : minHeight || "auto",
                            height: height || "auto",
                            maxHeight: maxHeight || "auto",
                          }
                    }
                  >
                    {loading && (
                      <div className="absolute z-10 flex h-full w-full flex-col items-center justify-center rounded-b-lg bg-white bg-opacity-60 px-3 pt-12 text-sm font-medium text-gray-900 backdrop-blur-sm sm:px-6">
                        {progress && progress > 0 ? (
                          <div className="w-[200px]">
                            <ProgressBar value={progress} showAnimation primary size="sm" />
                          </div>
                        ) : (
                          <>
                            <Loading />
                            <span className="sr-only">Loading</span>
                          </>
                        )}
                      </div>
                    )}

                    {!loading && data && data?.length == 0 && (
                      <div>
                        <div
                          colSpan={fields?.length}
                          className="absolute flex h-full w-full items-center justify-center pt-12 text-sm font-medium text-gray-900 sm:px-6"
                        >
                          {emptyMessage || "No results found"}
                        </div>
                      </div>
                    )}

                    {virtualized ? (
                      <VirtualTable
                        {...props}
                        fields={fields}
                        tableData={tableData}
                        height={computedHeight}
                        width="100%"
                        itemCount={tableData.length}
                        itemSize={props.itemSize || 57}
                        header={
                          <thead className="sticky top-0 z-10 table-header-group">
                            <tr className={classNames("", divide ? "divide-x" : "", "sticky top-0")}>
                              {fields?.map((o, i) => (
                                <th
                                  scope="col"
                                  key={i}
                                  onClick={() => {
                                    if (o.sortable === false) return;
                                    // three-way sort, asc, desc, none
                                    if (sort.field == o.field) {
                                      if (sort.dir == "asc") {
                                        setSort({ dir: "desc", field: o.field });
                                      } else {
                                        setSort({ dir: null, field: null });
                                      }
                                    } else {
                                      setSort({ dir: "asc", field: o.field });
                                    }
                                  }}
                                  className="m-0 h-9 border-0 p-0"
                                  style={{
                                    width: o.width || "auto",
                                  }}
                                >
                                  <div
                                    className={classNames(
                                      "w-full border-0 border-b border-gray-300 bg-gray-50 text-left text-sm font-semibold text-gray-900 hover:bg-gray-100",
                                      o.floating ? "right-0" : "",
                                      o.sortable === false ? "cursor-default" : "cursor-pointer",
                                      compact ? "h-9 px-3 py-2.5" : "h-12 px-3 py-3.5 sm:px-6",
                                      !toolbar ? classNames(i == 0 ? "rounded-tl-lg" : "", i == fields.length - 1 ? "rounded-tr-lg" : "") : ""
                                    )}
                                  >
                                    <span className="flex items-center justify-between">
                                      <span className="flex flex-1 justify-between whitespace-nowrap pr-2">{o.label || " "}</span>
                                      {o.sortable !== false && (
                                        <button
                                          className={classNames(
                                            "ml-auto text-gray-400 hover:text-gray-500",
                                            sort.field === o.field ? "opacity-100" : "opacity-0",
                                            !sort.dir ? "opacity-0" : "",
                                            "transition duration-150 ease-in-out"
                                          )}
                                        >
                                          {sort.dir === "asc" ? <IconSortAscending size={18} /> : <IconSortDescending size={18} />}
                                        </button>
                                      )}
                                    </span>
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                        }
                        row={Row}
                        footer={
                          footerData && (
                            <tfoot className="sticky bottom-0">
                              <tr className={classNames("", divide ? "divide-x" : "", "sticky top-0")}>
                                {footerData?.map((o, i) => (
                                  <td key={i} className="m-0 h-9 p-0">
                                    <div
                                      className={classNames(
                                        "w-full border-0 border-t border-gray-300 bg-gray-50 text-left text-sm font-semibold leading-none text-gray-900",
                                        i === 0 ? "rounded-bl-lg" : "",
                                        i === footerData.length - 1 ? "rounded-br-lg" : "",
                                        "flex items-center justify-end",
                                        compact ? "h-9 px-3 py-2.5" : "h-12 px-3 py-3.5 sm:px-6",
                                        "px-3",
                                        o.className
                                      )}
                                    >
                                      {o.value}
                                    </div>
                                  </td>
                                ))}
                              </tr>
                            </tfoot>
                          )
                        }
                      />
                    ) : (
                      <table
                        className={classNames("relative my-0 min-w-full border-collapse bg-white", flush ? "" : "rounded-lg")}
                        style={{ borderSpacing: 0 }}
                      >
                        <thead className="sticky top-0 z-10 print:relative">
                          <tr className={classNames("", divide ? "divide-x" : "", "sticky top-0 print:relative")}>
                            {fields?.map((o, i) => (
                              <th
                                scope="col"
                                key={i}
                                onClick={() => {
                                  if (o.sortable === false) return;
                                  // three-way sort, asc, desc, none
                                  if (sort.field == o.field) {
                                    if (sort.dir == "asc") {
                                      setSort({ dir: "desc", field: o.field });
                                    } else {
                                      setSort({ dir: null, field: null });
                                    }
                                  } else {
                                    setSort({ dir: "asc", field: o.field });
                                  }
                                }}
                                className="m-0 h-9 border-0 p-0"
                                style={{ width: o.width || "auto" }}
                              >
                                <div
                                  className={classNames(
                                    "flex w-full items-center border-0 border-b border-gray-300 bg-gray-100 text-left text-sm font-semibold leading-none text-gray-900",
                                    o.floating ? "right-0" : "",
                                    o.sortable === false ? "cursor-default" : "cursor-pointer",
                                    compact ? "h-9 px-3 py-2.5" : "h-12 px-3 py-3.5 sm:px-6",
                                    !toolbar
                                      ? classNames(
                                          i == 0 ? (flush ? "" : "rounded-tl-lg") : "",
                                          i == fields.length - 1 ? (flush ? "" : "rounded-tr-lg") : ""
                                        )
                                      : ""
                                  )}
                                >
                                  <span className="flex items-center justify-between">
                                    <span className="flex flex-1 justify-between whitespace-nowrap pr-2">{o.label || " "}</span>
                                    {o.sortable !== false && (
                                      <button
                                        className={classNames(
                                          "ml-auto text-gray-400 hover:text-gray-500",
                                          sort.field === o.field ? "opacity-100" : "opacity-0",
                                          !sort.dir ? "opacity-0" : "",
                                          "transition duration-150 ease-in-out"
                                        )}
                                      >
                                        {sort.dir === "asc" ? <IconSortAscending size={18} /> : <IconSortDescending size={18} />}
                                      </button>
                                    )}
                                  </span>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody
                          className="fancy-scroll relative overflow-auto rounded-lg"
                          // style={{ height: `calc(100% - 40px)` }}
                        >
                          {tableData?.length > 0 && (
                            <AnimatePresence>
                              {tableData?.map((row, rowI) => (
                                <motion.tr
                                  animate={{ opacity: 1 }}
                                  initial={{ opacity: 0 }}
                                  exit={{ opacity: 0 }}
                                  key={rowI}
                                  className={classNames("group relative rounded-b-lg", divide ? "divide-x" : "")}
                                >
                                  {fields?.map((col, colI) => (
                                    <td
                                      key={colI}
                                      className={classNames(
                                        rowI !== tableData.length - 1 ? "border-b border-gray-200" : "",
                                        "whitespace-nowrap text-sm text-gray-900",
                                        compact ? "py-2" : "py-4",
                                        col.className,
                                        col.floating
                                          ? "sticky right-0 bg-white bg-opacity-75 px-3 shadow-md backdrop-blur-sm"
                                          : compact
                                          ? "px-3"
                                          : "px-3 sm:px-6",
                                        rowI === tableData.length - 1 && colI === fields.length - 1 ? "rounded-bl-lg" : "",
                                        rowI === tableData.length - 1 && colI === 0 ? "rounded-br-lg" : ""
                                      )}
                                    >
                                      <div className="w-full">
                                        <FieldTransformer col={col} row={row} data={tableData} index={rowI} transform={transform} />
                                      </div>
                                    </td>
                                  ))}
                                </motion.tr>
                              ))}
                            </AnimatePresence>
                          )}
                        </tbody>
                        {footerData && (
                          <tfoot className="sticky bottom-0">
                            <tr className={classNames("", divide ? "divide-x" : "", "sticky top-0")}>
                              {footerData?.map((o, i) => (
                                <td key={i} className="m-0 h-9 p-0">
                                  <div
                                    className={classNames(
                                      "w-full border-0 border-t border-gray-300 bg-gray-50 text-left text-sm font-semibold leading-none text-gray-900",
                                      i === 0 ? "rounded-bl-lg" : "",
                                      i === footerData.length - 1 ? "rounded-br-lg" : "",
                                      "flex items-center justify-end",
                                      compact ? "h-9 px-3 py-2.5" : "h-12 px-3 py-3.5 sm:px-6",
                                      "px-3",
                                      o.className
                                    )}
                                  >
                                    {o.value}
                                  </div>
                                </td>
                              ))}
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    )}

                    {search && tableData?.length === 0 && (
                      <div>
                        <div
                          colSpan={columns?.length}
                          className="relative flex h-full w-full items-center justify-center py-12 text-sm font-medium text-gray-900 sm:px-6"
                        >
                          No results found for &quot;{search}&quot;
                        </div>
                      </div>
                    )}

                    {filters?.field && filters?.op && filters?.value && tableData?.length === 0 && (
                      <div>
                        <div
                          colSpan={columns?.length}
                          className="relative flex h-full w-full items-center justify-center py-12 text-sm font-medium text-gray-900 sm:px-6"
                        >
                          <span className="mr-2">No results found for</span>
                          <span className="mr-1 rounded-md border bg-gray-50 px-1 py-0.5">{filters?.field}</span>{" "}
                          <span className="mr-1 rounded-md border bg-gray-50 px-1 py-0.5">{filters?.op}</span>{" "}
                          <span className="mr-1 rounded-md border bg-gray-50 px-1 py-0.5">{filters?.value}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {pagination && (
                    <AdminTablePagination
                      pagination={{
                        ...pagination,
                        onNumber: (number) => {
                          onPaginate({ ...pagination, page: number });
                        },
                        onNext: () => {
                          onPaginate({ ...pagination, page: (pagination.current_page || 0) + 1 });
                        },
                        onPrev: () => {
                          onPaginate({ ...pagination, page: pagination.current_page > 1 ? pagination.current_page - 1 : 1 });
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {printable && (
        <table className="relative hidden min-w-full border-collapse divide-y divide-gray-300 border print:table">
          <thead className="table-header-group w-full bg-gray-100">
            <tr>
              {fields?.map((o, i) => (
                <th key={i} className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                  {o.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full divide-y divide-gray-200">
            {tableData?.map((row, rowI) => (
              <tr key={rowI}>
                {fields?.map((col, colI) => (
                  <td key={colI} className="whitespace-nowrap px-2 py-2 text-sm">
                    <FieldTransformer col={col} row={row} data={tableData} index={rowI} transform={transform} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {footerData && (
            <tfoot className="border-t-0 bg-gray-100">
              <tr>
                {footerData?.map((o, i) => (
                  <td key={i} className="whitespace-nowrap px-2 py-2 text-right text-sm">
                    {o.value}
                  </td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      )}
    </>
  );
}

const Row = ({ index }) => {
  const { tableData, fields, divide, compact, transform } = useContext(VirtualTableContext);
  const row = tableData[index];

  return (
    <tr key={index} className={classNames("group relative rounded-b-lg hover:bg-gray-50", divide ? "divide-x" : "")}>
      {fields?.map((col, colI) => (
        <td
          key={colI}
          className={classNames(
            index !== tableData.length - 1 ? "border-b border-gray-200" : "",
            "whitespace-nowrap text-sm font-medium text-gray-900",
            compact ? "py-2" : "py-4",
            col.className,
            col.floating
              ? "sticky right-0 bg-white bg-opacity-75 px-3 shadow-md backdrop-blur-sm group-hover:bg-gray-50"
              : compact
              ? "px-3"
              : "px-3 sm:px-6"
          )}
        >
          <div className="w-full">
            <FieldTransformer col={col} row={row} data={tableData} index={index} transform={transform} />
          </div>
        </td>
      ))}
    </tr>
  );
};

const Inner = forwardRef(function Inner({ children, ...rest }, ref) {
  const { header, footer, top } = useContext(VirtualTableContext);
  return (
    <div {...rest} ref={ref}>
      <table style={{ top, position: "absolute", width: "100%" }}>
        {header}
        <tbody className="fancy-scroll relative overflow-auto bg-white">{children}</tbody>
        {footer}
      </table>
    </div>
  );
});

const VirtualTable = ({ row, header, footer, ...rest }) => {
  const listRef = useRef();
  const [top, setTop] = useState(0);

  return (
    <VirtualTableContext.Provider value={{ ...rest, top, setTop, header, footer }}>
      <FixedSizeList
        {...rest}
        innerElementType={Inner}
        onItemsRendered={(props) => {
          const style =
            listRef.current &&
            // @ts-ignore private method access
            listRef.current._getItemStyle(props.overscanStartIndex);
          setTop((style && style.top) || 0);

          // Call the original callback
          rest.onItemsRendered && rest.onItemsRendered(props);
        }}
        ref={(el) => (listRef.current = el)}
      >
        {row}
      </FixedSizeList>
    </VirtualTableContext.Provider>
  );
};

function FieldTransformer({ transform, col, row, data, index }) {
  let rowData = data?.find((_, i) => i === index);

  let value = row[col.field] || dotProp.get(row, col.field);

  let returnValue =
    typeof transform == "function" ? transform(col.field, value, rowData || row) : BuiltinTransformers({ field: col.field, row }) || value;

  if (returnValue === value) {
    return BuiltinTransformers({ field: col.field, row: rowData }) || value || "";
  }

  return returnValue || value || "";
}

function BuiltinTransformers({ field, row }) {
  if (field === "status" && `${row.status_id}`) {
    return <OrderStatus order={row} />;
  }

  return row[field] || "";
}

export function AdminTableMore({ options }) {
  return (
    <Popover
      appendTo={document.body}
      placement="bottom-end"
      className=""
      button={
        <span className="mja-btn mja-btn-secondaryOutline cursor-pointer">
          <IconDotsVertical className="h-4 w-4" />
        </span>
      }
    >
      <div className="-mx-[9px] -my-[5px] w-[175px] overflow-hidden rounded-lg">
        {options?.map((o, i) => (
          <button
            key={i}
            className="mja-text-gray-700 mja-block mja-px-4 mja-py-2 mja-text-sm mja-text-left mja-w-full hover:bg-gray-100"
            onClick={o.onClick}
          >
            {o.text}
          </button>
        ))}
      </div>
    </Popover>
  );
}
