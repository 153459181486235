/* eslint-disable no-unused-vars */
import { APP_MODE } from "core/config/constants";
import { useAuth } from "core/hooks/useAuth";
import fetcher from "core/utils/fetcher";
import { IconCircleX, IconLock } from "@tabler/icons-react";

/**
 * Returns a promise of a boolean if the auth'd user matches a role
 * @param {string} role the role to check
 * @example const hasAccess = await hasRole('mojoactive')
 * @returns {Promise<Boolean>}
 */
export const hasRole = async (role) => {
  try {
    const user = await fetcher("/api/auth");
    return Array.isArray(role) ? role.indexOf(user.role) > -1 : user?.role === role;
  } catch (ex) {
    return false;
  }
};

export const isMJA = async () => {
  try {
    const user = await fetcher("/api/auth");
    return user.email.includes("domains@mojoactive.com");
  } catch (ex) {
    return false;
  }
};

export const ProtectedMessage = (props) => {
  if (!props.explain) return null;
  let message = props.message || "Permission Denied";
  let desc = props.desc || "Please contact support for more information.";
  return (
    <div className="flex flex-col items-center justify-center space-y-2 py-10">
      <IconLock size={50} className="text-gray-500" />
      <h1 className="text-2xl font-bold">{message}</h1>
      <p className="text-center">{desc}</p>
    </div>
  );
};

export const DisabledFeature = (props) => {
  if (!props.explain) return null;
  let message = props.message || "Feature Disabled";
  let desc = props.desc || "Please contact support for more information.";
  return (
    <div className="flex flex-col items-center justify-center space-y-2 py-10">
      <IconCircleX size={50} className="text-gray-500" />
      <h1 className="text-2xl font-bold">{message}</h1>
      <p className="text-center">{desc}</p>
    </div>
  );
};

export default function AdminProtected(props) {
  const { user, loading } = useAuth();
  if (loading) return null;
  return user ? user.role == "admin" ? props.children || null : <ProtectedMessage {...props} /> : <ProtectedMessage {...props} />;
}

export function MJAProtected(props) {
  const { user, loading } = useAuth();
  if (loading) return null;
  return user ? (
    user.email?.includes("domains@mojoactive.com") ? (
      props.children || null
    ) : (
      <ProtectedMessage {...props} />
    )
  ) : (
    <ProtectedMessage {...props} />
  );
}

/**
 *
 * @param {{ mode: (keyof APP_MODE) }} param0
 * @returns {JSX.Element || null}
 */
export function AppVisibility({ mode, children }) {
  if (!window.APP_MODE) return children ?? null;

  if (window.APP_MODE === mode) {
    return children ?? null;
  }

  return null;
}
